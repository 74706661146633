<template>
  <div class="wapPage">
    <div class="pageTitle" :style="getStyleConfig('welCome')">
      <div class="titleCon">
        <span></span>
        <p>{{ getText("welCome") }}</p>
        <div class="lang" @click="pageLangChange()" v-if="webJson.langType"> {{ getText("lang") }}</div>
        <span v-else></span>
      </div>
    </div>
    <div class="pageHead">
      <img :src="webJson.topBanner.icon" alt="">
      <div :class="menu ? 'menuToggle on' : 'menuToggle'" @click="menuToggle"><span></span></div>
    </div>
    <div class="menuGroup" v-if="menu">
      <div class="menuList" @click="linkTo(item.link)" v-for="(item, index) in ( pageLang === 1 ? webJson.navList : webJson.enNavList )" :key="index">
        {{ item.name }}
        <div class="childList" @click="linkTo(child.link)" v-for="(child, index) in item.children" :key="index">
          {{ child.name }}
        </div>
      </div>
    </div>
    <div class="pageCon">
      <div class="countDown" v-if="getTimeSate(webJson.countdown.time) && parseInt(webJson.countdown.offset) === 1 && webJson.countdown.switch" :style="getStyleConfig('countDown')">
        <countDown :time="webJson.countdown.time"></countDown>
        <img :src="getConfigText('countType')" alt="" v-if="getStyleConfig('countType')">
      </div>
      <router-view></router-view>
    </div>
    <div class="pageFoot" :style="getStyleConfig('footer')">
      <div class="footCon">
        <div class="footInfo">
          <p v-for="(item, index) in webJson.footerItems" :key="index" @click="linkTo(item.link)">
            {{ item.text }}
          </p>
        </div>
        <div class="footItem">
          <div class="footLogo" v-for="(item, index) in webJson.footerIcons" :key="index" @click="linkTo(item.link)">
            <img :src="item.url" alt="" />
          </div>
        </div>
      </div>
    </div>
    <a :href="getConfig.footerConfig.url" class="ipcItem">{{ getConfigText("ipc")}}</a>
  </div>
</template>

<script>
  import countDown from "../../../components/countDown";
  import config from "../../../webConfig";
  import { mapActions, mapGetters } from "vuex";
  export default {
    data() {
      return {
        value1: 0,
        webJson: config,
        countDown: {},
        alts: 0,
        pageLang: 1,
        navAct: -1,
        menu:false,
      };
    },
    components: {
      countDown: countDown
    },
    computed: {
      ...mapGetters(["getConfig", "getSpinType"])
    },
    watch: {
      getConfig(val){
        this.webJson = val
      }
    },
    methods: {
      menuToggle(){
        this.menu = !this.menu
      },
      pageLangChange() {
        this.pageLang = this.pageLang === 1 ? 2 : 1;
        this.$router.push({
          name: "wapHome",
          query: { lang: this.pageLang }
        });
      },
      toLink() {
        if (this.getConfig.footerConfig.url) {
          window.location.href = this.getConfig.footerConfig.url
          // window.open(this.getConfig.footerConfig.url,"_self")
        }
      },
      getTime(time, type) {
        if (!time) {
          return "";
        }
        let d = new Date(time);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let date = d.getDate();
        // let hour = d.getHours();
        // let minute = d.getMinutes();
        // let second = d.getSeconds();
        return type ? year : month + "/" + date;
      },
      checkTime(i) {
        if (i < 10) {
          i = "0" + i;
        }
        return i;
      },
      linkTo(link) {
        this.menu = false;
        if (!link) {
          return false;
        }
        if (link === "home") {
          this.$router.push({ name: "home" });
          return false
        }
        if (link.length === 8) {
          for (let x of this.webJson.articleList) {
            if (link === x.link) {
              this.$router.push({ name: "wapArticle", query: { id: link } });
              return false;
            }
          }
          return false;
        }
        if (link.length === 6) {
          this.$router.push({ name: "wapList", query: { id: link } });
          return false;
        }
        // if (link.indexOf("alts") !== -1) {
        //   this.$router.push({ name: "alts" });
        //   return false;
        // }
        if (link === "list") {
          this.$router.push({ name: "wapList", query: { id: link } });
          return false;
        }
        window.open(link);
      },
      getTimeSate(time) {
        if (time) {
          let leftTime = new Date(time) - new Date();
          return leftTime > 0;
        }
        return false;
      },
      getText(type) {
        if (type === "welCome") {
          let welComeConfig = (this.pageLang === 1 ? this.getConfig.welCome.config : this.getConfig.welCome.enConfig) || this.getConfig.welCome;
          return welComeConfig.text;
        }
        if (type === "lang") {
          return this.pageLang === 1 ? "English" : "中文";
        }
        if (type === "topBannerLink") {
          return this.pageLang === 1
            ? this.webJson.topBanner.link
            : this.webJson.topBanner.enLink;
        }
      },
      // 设置元素样式设置
      getStyleConfig(type) {
        if (type === "welCome") {
          let welComeConfig =  (this.pageLang === 1 ? this.getConfig.welCome.config : this.getConfig.welCome.enConfig) || {};
          return {
            color: welComeConfig.color || this.getConfig.pageColor,
            fontSize: welComeConfig.size,
            backgroundColor: welComeConfig.bgColor,
          }
        }
        if (type === "menuColor") {

        }
        if (type === "welComeType"){
          let welComeConfig =  (this.pageLang === 1 ? this.getConfig.welCome.config : this.getConfig.welCome.enConfig) || {};
          return welComeConfig.type === 2
        }
        if (type === "countType"){
          return this.getConfig.countdown.bgType === 2
        }
        if (type === "menu") {
          let navConfig = ( this.pageLang === 1 ? this.getConfig.navConfig.cn : this.getConfig.navConfig.en) || {};
          return {
            width: navConfig.type === 2 ? "100%" : "1200px",
            color: navConfig.font,
            backgroundColor: navConfig.background
          }
        }
        if (type === "menuGroup") {
          let navConfig = ( this.pageLang === 1 ? this.getConfig.navConfig.cn : this.getConfig.navConfig.en) || {};
          return {
            width: navConfig.type === 2 ? "1200px" : "100%",
            color: navConfig.font
          }
        }
        if (type === "navItem") {
          let navConfig = ( this.pageLang === 1 ? this.getConfig.navConfig.cn : this.getConfig.navConfig.en) || {};
          return {
            backgroundColor: navConfig.background || "#fff",
            // color: nvaConfig.font || this.getConfig.pageColor
          }
        }
        if (type === "lang") {
          return {
            borderColor: this.getConfig.pageColor,
            color: this.getConfig.pageColor
          }
        }
        if (type === "footer") {
          return {
            backgroundImage:  `url(${this.getConfig.footerConfig.icon})`,
            backgroundColor: this.getConfig.footerConfig.bgColor || "#1A2E42",
          }
        }
        if (type === "countDown") {
          return {
            // width: this.getConfig.countdown.type === 2 ? "100%" : "1200px",
            width: this.getConfig.countdown.type === 2 ? "100%" : "100%",
            backgroundColor: this.getConfig.countdown.bgColor || this.getConfig.pageColor
          }
        }
      },
      // 设置元素hover样式
      getHoverConfig(ev,type,hover) {
        let navConfig = ( this.pageLang === 1 ? this.getConfig.navConfig.cn : this.getConfig.navConfig.en) || {};
        let b1 = navConfig.backgroundAct || this.getConfig.pageColor;
        let b2 = navConfig.background || "#fff";
        ev.style.color = hover === 1 ? ( navConfig.fontAct || "#fff" ) : ( navConfig.font || "#2c3e50");
        ev.style.backgroundColor = hover === 1 ? b1 : type === "navItem"  ? b2 : "#fff"
      },
      // 设置元素内容
      getConfigText(type) {
        let str = "";
        if (type === "welCome") {
          let welComeConfig =  (this.pageLang === 1 ? this.getConfig.welCome.config : this.getConfig.welCome.enConfig) || {};
          str = welComeConfig.text  || "暂无欢迎语，请在右边进行【欢迎语配置】"
        }
        if (type === "lang"){
          str = this.pageLang === 1 ? "English" : "中文"
        }
        if (type === "welComeType") {
          let welComeConfig =  (this.pageLang === 1 ? this.getConfig.welCome.config : this.getConfig.welCome.enConfig) || {};
          str = welComeConfig.icon
        }
        if (type === "countType") {
          str = this.getConfig.countdown.icon
        }
        if (type === "footImg") {
          str = this.getConfig.footerConfig.icon
        }
        if (type === "ipc") {
          str =  "活动嘉提供技术支持";
          if (this.getConfig.footerConfig.ipc) {
            str += " | ICP备案号" + this.getConfig.footerConfig.ipc
          }
        }
        return str
      },
      showCrumbs(){
        let linkArray = ["list", "article","alts","videos"];
        return linkArray.indexOf(this.$route.name) >- 1
      }
    }
  }
</script>

<style lang="less">
  @import "../../../assets/less/wap";
</style>
